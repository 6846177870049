import { useCallback } from 'react';

type UseHashCalculator = {
  calculateHash: (file: ArrayBuffer) => Promise<string>;
};

function Uint8ArrayToHexString(array: Uint8Array) {
  let hexString = '';
  let h: string;
  for (let i = 0; i < array.length; i++) {
    h = array[i].toString(16);
    if (h.length === 1) {
      h = `0${h}`;
    }
    hexString += h;
  }
  const p = Math.pow(2, Math.ceil(Math.log2(hexString.length)));
  hexString = hexString.padStart(p, '0');
  return hexString;
}

// eslint-disable-next-line import/prefer-default-export
export const useHashCalculator = (): UseHashCalculator => {
  /**
   * The calculateHash function will use the Web Crypto API
   * to generate a SHA256 hash of the specified file.
   */
  const calculateHash = useCallback(async (file: ArrayBuffer) => {
    const result = file;
    if (!result) {
      throw new Error('Failed to read signed file');
    }
    const digested = await window.crypto.subtle.digest('SHA-256', file);
    const digestedArray = new Uint8Array(digested);
    return Uint8ArrayToHexString(digestedArray);
  }, []);

  return { calculateHash };
};
