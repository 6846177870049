import { useMemo } from 'react';
import { Ribbon } from '@tanium/react-ribbon';
import { CircleCheckIcon, HelpIcon, StateCriticalIcon, StateIdleIcon } from '@tanium/react-graphics';
import { useTheme } from '@tanium/react-theme-context';
import { Spinner } from '@tanium/react-spinner';
import { Stack } from '@tanium/react-stack';
import { NotificationBase } from '@tanium/react-notifications-base';
import { Box } from '@tanium/react-box';
import { SigningStatus, SigningStep } from './hooks/useSigningRequestCookies';

type Props = {
  step: SigningStep;
  currentStep: SigningStep;
  currentStatus: SigningStatus;
  errorMessage: string | undefined;
};

const SigningStepStatus = ({ step, currentStep, currentStatus, errorMessage }: Props) => {
  const theme = useTheme();

  const status = useMemo(() => {
    if (currentStatus === SigningStatus.NotStarted) {
      return <StateIdleIcon width={16} color={theme.colors.subtle} />;
    }
    if (step > currentStep) {
      return <StateIdleIcon width={16} color={theme.colors.subtle} />;
    }
    if (step < currentStep || currentStatus === SigningStatus.Success) {
      return <CircleCheckIcon width={16} color={theme.colors.success} />;
    }
    if (currentStatus === SigningStatus.Failure) {
      return <StateCriticalIcon width={16} color={theme.colors.error} />;
    }
    if (currentStatus === SigningStatus.InProgress) {
      return <Spinner size={16} />;
    }
    return <HelpIcon width={16} />;
  }, [currentStatus, currentStep, step, theme.colors.error, theme.colors.subtle, theme.colors.success]);

  const title = useMemo(() => {
    if (step === SigningStep.FetchingMetaData) return 'Fetch TC version and tanium-init.dat';
    if (step === SigningStep.Signing) return 'Request signed installer';
    if (step === SigningStep.Polling) return 'Wait for file to be signed';
    if (step === SigningStep.FetchingUploadDestination) return 'Determine S3 upload destination';
    if (step === SigningStep.Uploading) return 'Upload to S3';
    if (step === SigningStep.Finalizing) return 'Finalize';
    return 'N/A';
  }, [step]);

  const error = useMemo(() => {
    if (currentStep === step && !!errorMessage) {
      return (
        <Box pt={2} pb={2}>
          <NotificationBase type="error" role="status">
            {errorMessage}
          </NotificationBase>
        </Box>
      );
    }
    return null;
  }, [currentStep, errorMessage, step]);

  return (
    <Ribbon sideBar={status}>
      <Stack direction="column">
        {title}
        {error}
      </Stack>
    </Ribbon>
  );
};

export default SigningStepStatus;
