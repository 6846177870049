import { Button } from '@tanium/react-button';
import { Spinner } from '@tanium/react-spinner';
import { Stack } from '@tanium/react-stack';
import { useCallback, useContext, useEffect, useState } from 'react';
import { Api } from '../../utils/api';
import { ConfigContext } from './ConfigContext';
import { SigningRequestDetails, SigningStatus, SigningStep } from './hooks/useSigningRequestCookies';
import { useSigningWorkflow } from './hooks/useSigningWorkflow';
import SigningStepStatus from './SigningStepStatus';
import UploadComponent from './UploadComponent';

type Props = {
  domain: string;
  details: SigningRequestDetails;
  instance: string;
  updateStatus: (domain: string, status: SigningStatus, step: SigningStep) => void;
};

const SigningDetailedStatus = ({ domain, details, instance }: Props) => {
  const { config } = useContext(ConfigContext);

  const {
    status,
    step,
    tcVersion,
    datFile,
    clientType,
    accountId,
    isWaitingForPoll,
    errorMessage,
    setStatus,
    setStep,
    abort: abortPollRequest,
  } = useSigningWorkflow(domain, details.step, details.status, details.fileId, instance);

  const onFileSubmit = useCallback(
    (file: File) => {
      // eslint-disable-next-line @typescript-eslint/no-floating-promises
      (async () => {
        try {
          errorMessage.current = '';

          setStep(SigningStep.FetchingMetaData);
          setStatus(SigningStatus.InProgress);

          const metadata = await Api.getInstallerDetails(file, domain, instance, config!.mdmDomainName);

          tcVersion.current = metadata.tcVersion;
          datFile.current = metadata.taniumInitDat;
          accountId.current = metadata.accountId;

          // For now, we've decided to only build an installer for
          // x64 architectures. If we decide to let the user choose,
          // just replace this line with the value from `metadata`.
          clientType.current = 'x64';

          setStep(SigningStep.Signing);
        } catch (e) {
          setStatus(SigningStatus.Failure);
        }
      })();
    },
    [accountId, clientType, config, datFile, domain, errorMessage, instance, setStatus, setStep, tcVersion]
  );

  /**
   * If the user attempts to abort while we are polling for
   * the signature status, we will have to do a delayed abort
   * once the current pause interval is complete. The following
   * few hooks handle the orchestration of this abort process.
   */
  const [willAbortAfterPoll, setWillAbortAfterPoll] = useState(false);

  const resetState = useCallback(() => {
    setStatus(SigningStatus.NotStarted);
    setWillAbortAfterPoll(false);
  }, [setStatus]);

  const handleReset = useCallback(() => {
    if (isWaitingForPoll) {
      setWillAbortAfterPoll(true);
    } else {
      resetState();
    }
    abortPollRequest();
  }, [abortPollRequest, isWaitingForPoll, resetState]);

  useEffect(() => {
    if (willAbortAfterPoll && !isWaitingForPoll) {
      resetState();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isWaitingForPoll]);

  return (
    <>
      {willAbortAfterPoll && <Spinner />}
      {!willAbortAfterPoll && status !== SigningStatus.NotStarted && (
        <Stack direction="column" itemSpacing={1}>
          {Object.keys(SigningStep)
            .filter((key) => !isNaN(Number(SigningStep[key])))
            .map((s) => (
              <SigningStepStatus
                currentStep={step}
                currentStatus={status}
                step={SigningStep[s] as SigningStep}
                errorMessage={errorMessage.current}
              />
            ))}
          <Button onClick={handleReset}>Abort and Retry</Button>
        </Stack>
      )}
      {!willAbortAfterPoll && status === SigningStatus.NotStarted && <UploadComponent onFileSubmit={onFileSubmit} />}
    </>
  );
};

export default SigningDetailedStatus;
