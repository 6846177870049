import React, { useEffect } from 'react';
import { Auth } from 'aws-amplify';
import { useHistory } from 'react-router-dom';

const protectedRoute =
  (Comp: React.VFC<any>) =>
  (props): React.ReactElement<any, any> => {
    const history = useHistory();

    const checkAuthState = () => {
      Auth.currentAuthenticatedUser().catch((e) => {
        history.push('/');
      });
    };

    useEffect(() => {
      checkAuthState();
    });

    return <Comp {...props} />;
  };

export default protectedRoute;
