import React from 'react';
import { Box } from '@tanium/react-box';
import { Button } from '@tanium/react-button';
import { TextInput } from '@tanium/react-text-input';
import { TextBox, UploadTextStyling } from '../Utils/Styling';

interface Props {
  onFileSubmit: (f: File) => void;
}

const UploadComponent: React.VFC<Props> = ({ onFileSubmit }) => {
  const hiddenFileInput = React.useRef<HTMLInputElement>(null);
  const [, setFile] = React.useState<File | null>(null);
  const [fileName, setFileName] = React.useState('');

  const handleClick = () => {
    if (hiddenFileInput.current !== null) {
      hiddenFileInput.current.click();
    }
  };

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (e.target.files != null) {
      const fileUploaded = e.target.files[0];
      if (typeof fileUploaded !== 'undefined' && fileUploaded.name !== '') {
        setFile(fileUploaded);
        setFileName(fileUploaded.name);
        onFileSubmit(fileUploaded);
      }
    }
  };
  return (
    <>
      <TextBox p={1}>
        <h3>Upload the Customer Provided JSON File</h3>
        <Box>
          This file was just generated inside the Console. Ask the customer to download the file from the console to
          share it with you
        </Box>
      </TextBox>
      <Box p={1}>
        <Button variant="secondary" onClick={handleClick}>
          Choose File
        </Button>
        <TextInput style={UploadTextStyling} value={fileName} readOnly />
        <input
          type="file"
          ref={hiddenFileInput}
          // eslint-disable-next-line @typescript-eslint/no-misused-promises
          onChange={handleChange}
          accept=".json"
          data-testid="hidden-input"
          hidden
        />
      </Box>
    </>
  );
};

export default UploadComponent;
