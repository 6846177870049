import React, { ReactNode, useEffect, useState } from 'react';
import { Box } from '@tanium/react-box';
import styled from '@tanium/react-emotion-9';
import { css } from '@tanium/style-system';
import { HEX_292D38 } from '@tanium/colors';
import { defineColor } from '@tanium/react-theme-definition';
import { Auth } from 'aws-amplify';
import { Stack, StackChild } from '@tanium/react-stack';
import { TaniumSpinner } from '@tanium/react-tanium-spinner';
import logo from '../../static/tanium_logo.svg';
import Navigation, { NAVIGATION_WIDTH } from '../Navigation/Navigation';

const HeaderStyle = {
  borderHeight: 5,
  containerHeight: 60,
};

const HeaderBox = styled(Box)(
  css({
    bg: defineColor([HEX_292D38, HEX_292D38]),
    color: 'white',
    height: `${HeaderStyle.containerHeight}px`,
    width: '100%',
    alignItems: 'center',
    display: 'flex',
    whiteSpace: 'nowrap',
    position: 'fixed',
  })
);

const SignOut = styled('a')(
  css({
    fontSize: 12,
    ':hover': {
      color: '#DCDFE6',
      cursor: 'pointer',
    },
  })
);

const UserNameBox = styled(Box)(
  css({
    fontSize: 14,
  })
);

const ContentContainer = styled('div')(
  css({
    marginLeft: `${NAVIGATION_WIDTH + 10}px`,
  })
);

interface cognitoUser {
  getUsername: () => string;
}

const TaniumLogo: React.VFC = () => {
  // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
  return <img src={logo} alt="Tanium logo" height={24} />;
};
interface Props {
  children: ReactNode;
}

const Header: React.VFC<Props> = ({ children }) => {
  const [isLoaded, setLoaded] = useState(false);
  const [username, setUsername] = useState('');

  useEffect(() => {
    Auth.currentAuthenticatedUser()
      .then((currUser: cognitoUser) => {
        setUsername(currUser.getUsername());
        setLoaded(true);
      })
      .catch((error) => {
        // eslint-disable-next-line no-console
        console.log(error);
      });
  }, []);

  const onClickSignOut = () => {
    Auth.signOut().catch((error) => {
      // eslint-disable-next-line no-console
      console.log(error);
    });
  };

  return (
    <>
      {isLoaded ? (
        <Stack direction="column">
          <Box position="fixed" top={0} width="100%" zIndex={99}>
            <HeaderBox>
              <Box mx={3}>
                <TaniumLogo />
              </Box>
              <Box flexGrow={1} />
              <>
                <UserNameBox mx={2}>{username}</UserNameBox>
                <Box>|</Box>
                <Box mx={2}>
                  <SignOut onClick={onClickSignOut}>Sign Out</SignOut>
                </Box>
              </>
            </HeaderBox>
          </Box>
          <Stack>
            <Navigation />
            <StackChild grow={1}>
              <ContentContainer>{children}</ContentContainer>
            </StackChild>
          </Stack>
        </Stack>
      ) : (
        <TaniumSpinner />
      )}
    </>
  );
};

export default Header;
