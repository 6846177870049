import React from 'react';
import { Box } from '@tanium/react-box';
import { Panel } from '@tanium/react-panel';
import { Button } from '@tanium/react-button';
import { UploadBox as HandshakeBox, TextBox } from '../Utils/Styling';

interface Props {
  downloadHandshakeFile: () => void;
}
const HandshakeComponent: React.VFC<Props> = ({ downloadHandshakeFile }) => {
  const onClickDownload = (e: React.MouseEvent) => {
    downloadHandshakeFile();
  };

  return (
    <HandshakeBox>
      <Panel title="Step 3: Handshake File">
        <TextBox p={1}>
          <h3>Download the file below and share with your customer</h3>
          <Box>
            Once this file is downloaded, share with your customer, and ask them to upload the file to their MDM set up
            UI
          </Box>
        </TextBox>
        <Box p={1}>
          <Button onClick={onClickDownload} variant="secondary">
            Download and Share Handshake File
          </Button>
        </Box>
      </Panel>
    </HandshakeBox>
  );
};

export default HandshakeComponent;
