const prefix = 'tux.onboarding';

export const ONBOARDER_ROUTE = {
  name: ['Onboarder'],
  key: `${prefix}.onboarder`,
  url: '/onboarder',
};

export const SIGNING_ROUTE = {
  name: ['Signing'],
  key: `${prefix}.signing`,
  url: '/installer-request',
};

// eslint-disable-next-line import/no-anonymous-default-export
export default [ONBOARDER_ROUTE, SIGNING_ROUTE];
