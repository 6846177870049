import Amplify from 'aws-amplify';
import { Api, ConfigResponse } from './api';

export const configureAmplify = (response: ConfigResponse) => {
  Amplify.configure({
    region: response.internalCognitoUserPoolRegion,
    userPoolId: response.internalCognitoUserPoolId,
    userPoolWebClientId: response.internalCognitoAppClient,
    mandatorySignIn: false,
    oauth: {
      domain: response.internalCognitoDomain,
      redirectSignIn: `${window.location.origin}/onboarder`,
      redirectSignOut: `${window.location.origin}`,
      scope: ['openid', 'email', 'profile'],
      responseType: 'code',
    },
  });
};

// used for local dev. Consumes values from envfile.
export const localConfiguration = () => {
  const localConfig: ConfigResponse = {
    mdmDomainName: process.env.REACT_APP_MDM_DOMAIN_NAME!,
    instanceList: [process.env.REACT_APP_LOCAL_INSTANCE_NAME!],
    internalCognitoAppClient: process.env.REACT_APP_INTERNAL_COGNITO_APP_CLIENT!,
    internalCognitoDomain: process.env.REACT_APP_INTERNAL_COGNITO_DOMAIN!,
    internalCognitoAdditionalCallbackUrls: [''],
    internalCognitoUserPoolId: process.env.REACT_APP_INTERNAL_COGNITO_USER_POOL_ID!,
    internalCognitoUserPoolRegion: process.env.REACT_APP_INTERNAL_COGNITO_USER_POOL_REGION!,
    signingServiceHost: process.env.REACT_APP_SIGNING_SERVICE_HOST!,
  };
  return localConfig;
};

export const ConfigureApp = async () => {
  let config: ConfigResponse;
  if (process.env.NODE_ENV === 'development') {
    config = localConfiguration();
  } else {
    config = await Api.fetchConfig();
  }

  configureAmplify(config);
  return config;
};
