import { Stack } from '@tanium/react-stack';
import React, { useCallback } from 'react';
import styled from '@tanium/react-emotion-9';
import { css } from '@tanium/style-system';
import { useHistory } from 'react-router-dom';
import { QuickLinksIcon } from '@tanium/react-graphics';
import { ONBOARDER_ROUTE, SIGNING_ROUTE } from './routes';

const routes = [
  { name: 'Onboarder', url: ONBOARDER_ROUTE.url },
  { name: 'Signer', url: SIGNING_ROUTE.url },
];

export const NAVIGATION_WIDTH = 125;

const Container = styled('div')(
  css({
    backgroundColor: '#F2F6FA',
    marginTop: '60px',
    height: '100%',
    padding: '8px',
    marginRight: '40px',
    width: `${NAVIGATION_WIDTH}px`,
    position: 'absolute',
  })
);

const Route = styled('div')(
  css({
    padding: '8px',
    width: '75px',
    cursor: 'pointer',
    fontSize: '14px',
  })
);

const Navigation = () => {
  const history = useHistory();

  const handleRouteChange = useCallback(
    (url: string) => () => {
      history.push(url);
    },
    [history]
  );

  return (
    <Container>
      <Stack direction="column" itemSpacing={1} itemDividers>
        {routes.map((route) => (
          <Route onClick={handleRouteChange(route.url)}>
            <Stack itemSpacing={1}>
              <QuickLinksIcon width={16} />
              {route.name}
            </Stack>
          </Route>
        ))}
      </Stack>
    </Container>
  );
};

export default Navigation;
