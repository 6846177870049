/* eslint-disable @typescript-eslint/no-unsafe-return */
/* eslint-disable no-await-in-loop */
import { Auth } from 'aws-amplify';
import axios, { AxiosError, AxiosInstance, AxiosRequestConfig, CancelToken } from 'axios';
import mapKeys from 'lodash/mapKeys';
import camelCase from 'lodash/camelCase';
import { v4 as uuidv4 } from 'uuid';

const TENANT_ENTITY_VERSION = '1.1';

export type InstallerMetaData = {
  tcVersion: string;
  taniumInitDat: string;
  clientType: string;
  accountId: string;
};

export type StorageLinkInfo = {
  uploadURLs: {
    expiresOn: number;
    partSequenceNumber: number;
    partUploadURL: string;
  }[];
  uploadId: string;
};

export type PartInfo = {
  partSequenceNumber: number;
  partId: string;
};

async function fileToJSON(file: File) {
  return new Promise((resolve, reject) => {
    const fileReader = new FileReader();
    fileReader.onload = (event: ProgressEvent<FileReader>) => {
      const fileData = event.target!.result as string;
      try {
        resolve(JSON.parse(fileData));
      } catch (error) {
        reject(error);
      }
    };
    fileReader.onerror = () => reject(new Error(`Error occurred reading file: ${file.name}`));
    fileReader.readAsText(file);
  });
}

function getBlobFromBase64(base64: string) {
  const byteCharacters = atob(base64);
  const byteNumbers = new Array(byteCharacters.length);
  for (let i = 0; i < byteCharacters.length; i++) {
    byteNumbers[i] = byteCharacters.charCodeAt(i);
  }
  const byteArray = new Uint8Array(byteNumbers);
  return new Blob([byteArray]);
}

export const Api = {
  uploadOnboardingFile: async (payload: File, instance: string, mdmDomainName: string): Promise<Blob> => {
    const apiClient = getApiClient();

    let fileEndpointURL = '/tenants/OnPremOnboarding';
    if (process.env.NODE_ENV !== 'development') {
      fileEndpointURL = `https://onboarding.shared.${instance}-manage.${mdmDomainName}/tenants/OnPremOnboarding`;
    }

    const jsonPayload = await fileToJSON(payload).catch((err) => {
      throw err;
    });
    const apiResponse = await apiClient
      .post<string>(fileEndpointURL, jsonPayload, {
        headers: {
          'Content-Type': 'application/json',
          'api-version': TENANT_ENTITY_VERSION,
        },
      })
      .catch((err) => {
        throw err;
      });

    const enc = new TextEncoder();
    const handshakeBlob = new Blob([enc.encode(apiResponse.data)], {
      type: 'text/html',
    });
    return handshakeBlob;
  },

  getInstallerDetails: async (
    payload: File,
    domain: string,
    instance: string,
    mdmDomainName: string
  ): Promise<InstallerMetaData> => {
    const apiClient = getApiClient();

    let installerDetailsURL = '/tenants/getdetails';
    if (process.env.NODE_ENV !== 'development') {
      installerDetailsURL = `https://onboarding.shared.${instance}-manage.${mdmDomainName}${installerDetailsURL}`;
    }

    const jsonPayload: any = await fileToJSON(payload).catch((err) => {
      throw err;
    });

    // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access
    jsonPayload.domain = domain;

    const apiResponse = await apiClient
      .post<InstallerMetaData>(installerDetailsURL, jsonPayload, {
        headers: {
          'Content-Type': 'application/json',
          'api-version': TENANT_ENTITY_VERSION,
        },
      })
      .catch((err) => {
        throw err;
      });

    return apiResponse.data;
  },

  createSignedInstaller: async (
    host: string,
    clientVersion: string,
    datFile: string,
    clientType: string
  ): Promise<string> => {
    const apiClient = getApiClient();

    const generateClientURL = `${host}/generate_mdm_client`;

    const datFileBlob = getBlobFromBase64(datFile);

    const body = new FormData();
    body.append('datfile', datFileBlob);
    body.append('clientversion', clientVersion);
    body.append('clienttype', clientType);

    const response = await apiClient.post<{ ['file-id']: string }>(generateClientURL, body);

    return response.data['file-id'];
  },

  pollSignatureStatus: async (
    host: string,
    fileId: string,
    cancelToken: CancelToken,
    setWaiting: (waiting: boolean) => void
  ): Promise<ArrayBuffer | undefined> => {
    const apiClient = getApiClient();

    const fileStatusURL = `${host}/files/${fileId}`;

    // If we poll for more than 15 minutes, assume the request to sign failed.
    const timeoutMs = 15 * 60 * 1000;
    const intervalMs = 10 * 1000;
    let attempt = 1;

    while (attempt * intervalMs < timeoutMs) {
      try {
        const apiResponse = await apiClient.get<ArrayBuffer>(fileStatusURL, {
          cancelToken,
          responseType: 'arraybuffer',
        });
        return apiResponse ? apiResponse.data : undefined;
      } catch (err) {
        if (axios.isCancel(err)) return undefined;

        // 400 response means the file could not be found
        // or is just not complete yet..
        if ((err as AxiosError).response?.status === 400) {
          setWaiting(true);
          await new Promise((resolve) => setTimeout(resolve, intervalMs));
          setWaiting(false);
          attempt += 1;
        } else {
          throw err;
        }
      }
    }

    throw new Error('Timed out while polling for status');
  },

  getUploadDestination: async (
    accountId: string,
    uploadSHA256Hash: string,
    size: number | undefined,
    instance: string,
    mdmDomainName: string
  ): Promise<StorageLinkInfo> => {
    const apiClient = getApiClient();

    let storageLinkURL = '/tenants/createurls';
    if (process.env.NODE_ENV !== 'development') {
      storageLinkURL = `https://onboarding.shared.${instance}-manage.${mdmDomainName}${storageLinkURL}`;
    }

    // This should be overridden in the onboarding service.
    const allowedCIDRs = '0.0.0.0/0';
    const numUploadParts = 1;

    const body = { accountId, uploadSHA256Hash, allowedCIDRs, numUploadParts, size };

    const apiResponse = await apiClient
      .post<StorageLinkInfo>(storageLinkURL, body, {
        headers: {
          'Content-Type': 'application/json',
          'api-version': TENANT_ENTITY_VERSION,
        },
      })
      .catch((err) => {
        throw err;
      });

    return apiResponse.data;
  },

  uploadFileToS3: async (file: ArrayBuffer, uploadUrl: string): Promise<string> => {
    const apiClient = getApiClient();

    const apiResponse = await apiClient
      .put<StorageLinkInfo>(uploadUrl, file, {
        headers: {
          'Content-Type': 'application/octet-stream',
        },
        transformRequest: [
          (data, headers) => {
            if (!headers) return data;
            // eslint-disable-next-line no-param-reassign
            delete headers.Authorization;
            return data;
          },
        ],
      })
      .catch((err) => {
        throw err;
      });

    return apiResponse.headers.etag;
  },

  finalizeInstallerUpload: async (
    accountId: string,
    uploadId: string,
    partInfos: PartInfo[],
    version: string,
    instance: string,
    mdmDomainName: string
  ): Promise<string | undefined> => {
    const apiClient = getApiClient();

    let installerDetailsURL = '/tenants/finalizeupload';
    if (process.env.NODE_ENV !== 'development') {
      installerDetailsURL = `https://onboarding.shared.${instance}-manage.${mdmDomainName}${installerDetailsURL}`;
    }

    const body = {
      accountId,
      uploadId,
      partInfos,
      version,
    };

    const response = await apiClient
      .post<{ appId: string }>(installerDetailsURL, body, {
        headers: {
          'Content-Type': 'application/json',
          'api-version': TENANT_ENTITY_VERSION,
        },
      })
      .catch((err) => {
        throw err;
      });

    return response.data?.appId;
  },

  fetchConfig: async (): Promise<ConfigResponse> => {
    const apiClient = axios.create();
    const response = await apiClient.get<ConfigResponse>('/config');
    const responseJson: ConfigResponse = mapKeys(response.data, (value, key) => camelCase(key)) as ConfigResponse;
    return responseJson;
  },
};

/**
 * Gets an instance of Axios for calling the onboarding API.
 * This instance will include the access token if there is an authenticated user session.
 */
export const getApiClient = (): AxiosInstance => {
  const apiClient = axios.create();
  apiClient.interceptors.request.use(addAccessTokenInterceptor);
  apiClient.interceptors.request.use(addActivityIDInterceptor);
  return apiClient;
};

type Headers = Record<string | 'Authorization', string>;

/**
 * Gets an access token for the currently authenticated user, or undefined if there is not a valid user session.
 * Refreshes the access token if the current one is expired.
 */
export const getAccessToken = async (): Promise<string | undefined> => {
  try {
    // This will automatically refresh the accessToken and idToken if they are expired
    // and the refreshToken is a valid. Otherwise it will return the cached tokens.
    const session = await Auth.currentSession();
    return session.getAccessToken().getJwtToken();
  } catch (e) {
    // eslint-disable-next-line no-console
    console.log(e);
    return undefined;
  }
};

export const addAccessTokenInterceptor = async (config: AxiosRequestConfig) => {
  const headers = config.headers as Headers;
  const accessToken = await getAccessToken();

  if (accessToken) {
    headers.Authorization = `Bearer ${accessToken}`;
  }
  return config;
};

export const addActivityIDInterceptor = (config: AxiosRequestConfig) => {
  const headers = config.headers as Headers;

  headers['activity-id'] = uuidv4();
  return config;
};

export type ConfigResponse = {
  mdmDomainName: string; // for the API client
  instanceList: string[]; // for the onboarding page
  internalCognitoAppClient: string; // The rest of the vars are for the IDP
  internalCognitoDomain: string;
  internalCognitoAdditionalCallbackUrls: string[];
  internalCognitoUserPoolId: string;
  internalCognitoUserPoolRegion: string;
  signingServiceHost: string;
};
