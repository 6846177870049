import { createContext, Dispatch, SetStateAction, useState } from 'react';
import { ConfigResponse } from '../../utils/api';

export interface ConfigContextInterface {
  config: ConfigResponse | undefined;
  setConfig: Dispatch<SetStateAction<ConfigResponse | undefined>>;
}

export const INITIAL_CONFIG_CONTEXT: ConfigContextInterface = {
  config: undefined as unknown as ConfigResponse,
  // eslint-disable-next-line @typescript-eslint/no-empty-function
  setConfig: () => {},
};

Object.freeze(INITIAL_CONFIG_CONTEXT);

export const ConfigContext = createContext<ConfigContextInterface>(INITIAL_CONFIG_CONTEXT);

export const useConfigContext = (): ConfigContextInterface => {
  const [config, setConfig] = useState<ConfigResponse>();

  return {
    config,
    setConfig,
  };
};
