import React from 'react';
import { Auth } from 'aws-amplify';

const LoginPage: React.VFC = () => {
  React.useEffect(() => {
    Auth.federatedSignIn().catch((error) => {
      // eslint-disable-next-line no-console
      console.log(error);
    });
  }, []);

  return <div />;
};

export default LoginPage;
