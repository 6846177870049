import React from 'react';
import range from 'lodash/range';
import { Well } from '@tanium/react-well';
import { Box } from '@tanium/react-box';
import { DataColumn, DataGrid, ColumnsChangeEvent } from '@tanium/react-data-grid';
import { IconButton } from '@tanium/react-icon-button';
import { EditIcon } from '@tanium/react-graphics';

const BATCH_SIZE = 25;

interface Props {
  setEmail: (e: string) => void;
  setDomain: (e: string) => void;
  setRenderTable: (e: boolean) => void;
  setStart: (e: boolean) => void;
  setSend: (e: boolean) => void;
  setHasUploaded: (e: boolean) => void;
}

const CustomerTableComponent: React.VFC<Props> = ({
  setEmail,
  setDomain,
  setRenderTable,
  setStart,
  setSend,
  setHasUploaded,
}) => {
  const { data, isLoading, getMoreData, currentDataAvailable, totalDataAvailable } = useFakeServer();
  const initialColumns: DataColumn[] = [
    {
      label: 'Customer',
      dataKey: 'customer',
    },
    {
      label: 'Region',
      dataKey: 'region',
    },
    {
      label: 'Created At',
      dataKey: 'data',
    },
    {
      label: 'Domain',
      dataKey: 'domain',
    },
    {
      label: 'Email',
      dataKey: 'email',
    },
    {
      label: 'Notification Email(s)',
      dataKey: 'notifications',
    },
    {
      label: 'Status',
      dataKey: 'status',
    },
    {
      label: 'Action',
      dataKey: 'action',
      cellRenderer: ({ rowData }) => {
        const casted = rowData as ServerData;
        return (
          <IconButton
            height={16}
            width={16}
            icon={EditIcon}
            label="Edit"
            onClick={() => {
              setEmail(casted.email);
              setDomain(casted.domain);
              setStart(true);
              setRenderTable(false);
              setSend(true);
            }}
          />
        );
      },
    },
  ];
  const gridRef = React.useRef(null);
  const [columns, setColumns] = React.useState(initialColumns);

  const dataGetter = React.useCallback(({ index: i }: { index: number }) => data[i], [data]);
  const rowIdGetter = React.useCallback(
    ({ index }: { index: number }) => {
      const row = dataGetter({ index });
      return row.id;
    },
    [dataGetter]
  );
  const isRowLoaded = React.useCallback((indexObject: { index: number }) => !!dataGetter(indexObject), [dataGetter]);
  const loadMoreRows = React.useCallback(
    ({ startIndex, stopIndex }: { startIndex: number; stopIndex: number }) => {
      getMoreData({ startIndex, stopIndex });
    },
    [getMoreData]
  );
  const onColumnsChange = React.useCallback(
    (e: ColumnsChangeEvent, applyChanges: (currentColumns: DataColumn[], newColumns: DataColumn[]) => DataColumn[]) => {
      const changedColumns = applyChanges(columns, e.columns);
      setColumns(changedColumns);
    },
    [columns]
  );

  return (
    <Box p={1}>
      <Well>
        <DataGrid
          ref={gridRef}
          height={400}
          columns={columns}
          rowCount={currentDataAvailable}
          totalRowCount={totalDataAvailable}
          dataGetter={dataGetter}
          rowIdGetter={rowIdGetter}
          isLoading={isLoading}
          isRowLoaded={isRowLoaded}
          loadMoreRows={loadMoreRows}
          onColumnsChange={onColumnsChange}
          minimumBatchSize={BATCH_SIZE}
        />
      </Well>
    </Box>
  );
};

// eslint-disable-next-line @typescript-eslint/no-unsafe-call
const rawData: string[][] = Array.from(range(0, 50)).map((i) => [
  `${i}`,
  'Acme, Co.',
  'prd-na-1',
  '9/21/21 - 1:37 PM PST',
  `domainname${i}`,
  'customer@acme.co',
  'customer@acme.co,other@acme.co',
  'Complete',
]);

interface ServerData {
  id: string;
  customer: string;
  region: string;
  data: string;
  domain: string;
  email: string;
  notifications: string;
  status: string;
}

const serverData: readonly ServerData[] = Object.freeze(
  rawData.map(([id, customer, region, data, domain, email, notifications, status]) => ({
    id,
    customer,
    region,
    data,
    domain,
    email,
    notifications,
    status,
  }))
);

interface DataRange {
  startIndex: number;
  stopIndex: number;
  filter: string;
  originalData: ServerData[];
}

export const useFakeServer = () => {
  const [isLoading, setIsLoading] = React.useState(false);
  const [data, setData] = React.useState(serverData.slice(0, 100));
  const [currentDataAvailable, setCurrentDataAvailable] = React.useState(rawData.length);
  const totalDataAvailable = serverData.length;

  const getDataRange = ({ startIndex, stopIndex, filter, originalData }: DataRange) => {
    const oldData = [...originalData];
    const filteredData = [...serverData].filter((item) =>
      // eslint-disable-next-line @typescript-eslint/restrict-template-expressions
      Object.keys(item).some((key) => `${item[key]}`.toLowerCase().includes(filter.toLowerCase()))
    );
    const newData = filteredData.slice(startIndex, stopIndex + 1).reduce((allData, item, i) => {
      // eslint-disable-next-line no-param-reassign
      allData[startIndex + i] = item;
      return allData;
    }, oldData);
    return {
      data: newData,
      totalCount: filteredData.length,
    };
  };

  const getMoreData = React.useCallback(
    (args) => {
      // eslint-disable-next-line @typescript-eslint/no-unsafe-argument
      const { data: newData, totalCount } = getDataRange({ ...args, originalData: data });
      setData(newData);
      setCurrentDataAvailable(totalCount);
    },
    [data]
  );

  const getNewData = React.useCallback((args) => {
    // eslint-disable-next-line @typescript-eslint/no-unsafe-argument
    const { data: newData, totalCount } = getDataRange({ ...args, originalData: [] });
    setIsLoading(true);
    setData(newData);
    setCurrentDataAvailable(totalCount);
    setIsLoading(false);
  }, []);

  return { data, getMoreData, getNewData, isLoading, totalDataAvailable, currentDataAvailable };
};

export default CustomerTableComponent;
