import React, { useEffect } from 'react';
import { BrowserRouter, Switch, Route } from 'react-router-dom';
import { ThemeProvider as EmotionThemeProvider } from 'emotion-theming';
import { ThemeProvider as TaniumThemeProvider } from '@tanium/react-theme-context';
import { Box } from '@tanium/react-box';
import { TaniumSpinner } from '@tanium/react-tanium-spinner';
import { bluestar } from '@tanium/theme-data';
import InstallerRequestPage from './pages/InstallerRequestPage';
import OnboardingPage from './pages/OnboardingPage';
import LoginPage from './pages/LoginPage';
import { ConfigureApp } from './utils/config';
import { ConfigContext, useConfigContext } from './components/TaniumComponents/ConfigContext';
import { ONBOARDER_ROUTE, SIGNING_ROUTE } from './components/Navigation/routes';

const App: React.VFC = () => {
  const configContext = useConfigContext();

  useEffect(() => {
    const configure = async () => {
      const appConfig = await ConfigureApp();
      configContext.setConfig(appConfig);
    };

    configure().catch((err) => {
      // eslint-disable-next-line no-console
      console.log(err);
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <EmotionThemeProvider theme={bluestar}>
      <TaniumThemeProvider theme={bluestar}>
        {!configContext.config && (
          <Box height="100vh">
            <TaniumSpinner />
          </Box>
        )}
        {configContext.config && (
          <BrowserRouter>
            <ConfigContext.Provider value={configContext}>
              <Switch>
                <Route path={ONBOARDER_ROUTE.url}>
                  <OnboardingPage />
                </Route>
                <Route path={SIGNING_ROUTE.url}>
                  <InstallerRequestPage />
                </Route>
                <Route path="/">
                  <LoginPage />
                </Route>
              </Switch>
            </ConfigContext.Provider>
          </BrowserRouter>
        )}
      </TaniumThemeProvider>
    </EmotionThemeProvider>
  );
};

export default App;
