import { useCallback, useContext, useState } from 'react';
import { ThemeFontWeights } from '@tanium/theme-data';
import { FormRow, FormSection } from '@tanium/react-form';
import { FormSelectInput } from '@tanium/react-form-select-input';
import { Box } from '@tanium/react-box';
import { FormRadioInput } from '@tanium/react-form-radio-input';
import { FormTextInput } from '@tanium/react-form-text-input';
import { Button } from '@tanium/react-button';
import SigningRequestDetails from '../components/TaniumComponents/SigningRequestDetails';
import { useActiveSigningRequests } from './hooks/useActiveSigningRequests';
import { ConfigContext } from '../components/TaniumComponents/ConfigContext';
import { TitleBox } from './Styling';
import HeaderComponent from '../components/Header/HeaderComponent';

const subtitle =
  'To begin, choose an instance, and then click "Start New Request" or choose an existing customer domain.';

const InstallerRequestPage = () => {
  const [domain, setDomain] = useState<string>();
  const [existingDomain, setExistingDomain] = useState<string>();
  const [instance, setInstance] = useState<string>();
  const [showExisting, setShowExisting] = useState(false);
  const [customerChosen, setCustomerChosen] = useState(false);

  const { config } = useContext(ConfigContext);
  const { domains } = useActiveSigningRequests();

  // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access
  const instances = (config!.instanceList ?? ['foo', 'bar']).map((item: string) => {
    return { value: item, label: item };
  });

  const handleInstanceChange = useCallback((e: { value: string }) => {
    setInstance(e.value);
  }, []);

  const handleExistingDomainChange = useCallback((e: { value: string }) => {
    setExistingDomain(e.value);
    setCustomerChosen(false);
  }, []);

  const handleDomainChange = useCallback((e: { target: { value: string } }) => {
    setDomain(e.target.value);
    setCustomerChosen(false);
  }, []);

  const handleBegin = useCallback(() => {
    setCustomerChosen(true);
  }, []);

  const handleChangeCustomer = useCallback(() => {
    setCustomerChosen(false);
    setDomain(undefined);
    setExistingDomain(undefined);
  }, []);

  const handleRadioToggle = useCallback(
    (useExisting: boolean) => () => {
      if (!useExisting) {
        setDomain(undefined);
      } else {
        setExistingDomain(undefined);
      }
      setShowExisting(useExisting);
    },
    []
  );

  return (
    <HeaderComponent>
      <Box style={{ fontSize: '14px' }}>
        <TitleBox fontWeight={ThemeFontWeights.Bold} mt={8} pt={2}>
          Single File Installer Upload
        </TitleBox>
        <Box p={2}>
          <FormSection title="Customer" subtitle={subtitle}>
            <FormRow dataKey="instance" label="Instance" required data-testid="instance-input">
              <FormSelectInput
                options={instances}
                data-testid=""
                value={instances.find((i) => i.value === instance)}
                onChange={handleInstanceChange}
              />
            </FormRow>
            {!!instance && (
              <FormRow labelId="domain" label="Customer Domain" required data-testid="domain">
                {customerChosen ? (
                  <Box>{existingDomain ?? domain}</Box>
                ) : (
                  <Box>
                    <FormRadioInput
                      label="Start request for new domain"
                      onClick={handleRadioToggle(false)}
                      checked={!showExisting}
                      id="start-new"
                    />
                    <FormRadioInput
                      label="Choose from domains with existing requests"
                      onClick={handleRadioToggle(true)}
                      checked={showExisting}
                      id="choose-existing"
                    />
                    {showExisting && (
                      <FormSelectInput
                        options={domains}
                        data-testid="existing-domain"
                        dataKey="existingDomain"
                        onChange={handleExistingDomainChange}
                        value={domains.find((d) => d.value === existingDomain)}
                      />
                    )}
                    {showExisting || (
                      <FormTextInput
                        value={domain}
                        onChange={handleDomainChange}
                        dataKey="newDomain"
                        style={{ height: '16px' }}
                        placeholder="example.com"
                      />
                    )}
                  </Box>
                )}
              </FormRow>
            )}
            {!customerChosen && !!instance && !!(existingDomain ?? domain) && (
              <Button onClick={handleBegin} style={{ width: '125px' }}>
                {showExisting ? 'View Status' : 'Start Request'}
              </Button>
            )}
            {customerChosen && (
              <Button onClick={handleChangeCustomer} style={{ width: '150px' }}>
                Change Customer
              </Button>
            )}
          </FormSection>
          {customerChosen && (
            <FormSection title="Status">
              <SigningRequestDetails domain={existingDomain ?? domain!} instance={instance!} />
            </FormSection>
          )}
        </Box>
      </Box>
    </HeaderComponent>
  );
};

export default InstallerRequestPage;
