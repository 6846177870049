import { useMemo } from 'react';
import { useSigningRequestCookies } from '../../components/TaniumComponents/hooks/useSigningRequestCookies';

type UseActiveSigningRequests = {
  domains: { label: string; value: string }[];
};

// eslint-disable-next-line import/prefer-default-export
export const useActiveSigningRequests = (): UseActiveSigningRequests => {
  const { getAllStatuses } = useSigningRequestCookies();

  const domains = useMemo(() => {
    const statuses = getAllStatuses();
    return Object.keys(statuses)
      .filter((s) => !!s)
      .map((s) => ({ label: s, value: s }));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [getAllStatuses, document.cookie]);

  return {
    domains,
  };
};
