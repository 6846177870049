import { useMemo } from 'react';
import { useSigningRequestCookies } from './hooks/useSigningRequestCookies';
import SigningDetailedStatus from './SigningDetailedStatus';

type Props = {
  domain: string;
  instance: string;
};

const SigningRequestDetails = ({ domain, instance }: Props) => {
  const { getStatus, initializeStatus, updateStatus } = useSigningRequestCookies();

  const details = useMemo(() => {
    const status = getStatus(domain);

    if (!status) {
      return initializeStatus(domain);
    }

    return status;
  }, [domain, getStatus, initializeStatus]);

  return (
    <SigningDetailedStatus
      key={domain}
      domain={domain}
      details={details}
      updateStatus={updateStatus}
      instance={instance}
    />
  );
};

export default SigningRequestDetails;
