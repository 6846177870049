/* eslint-disable @typescript-eslint/no-floating-promises */
import { MutableRefObject, useCallback, useContext, useEffect, useRef, useState } from 'react';
import axios, { CancelTokenSource } from 'axios';
import { Api, PartInfo, StorageLinkInfo } from '../../../utils/api';
import { ConfigContext } from '../ConfigContext';
import { useHashCalculator } from './useHashCalculator';
import { SigningStatus, SigningStep, useSigningRequestCookies } from './useSigningRequestCookies';

type UseSigningWorkflow = {
  status: SigningStatus;
  step: SigningStep;
  tcVersion: MutableRefObject<string | undefined>;
  datFile: MutableRefObject<string | undefined>;
  clientType: MutableRefObject<string | undefined>;
  accountId: MutableRefObject<string | undefined>;
  errorMessage: MutableRefObject<string | undefined>;
  isWaitingForPoll: boolean;
  setStatus: (status: SigningStatus) => void;
  setStep: (step: SigningStep) => void;
  abort: () => void;
};

// eslint-disable-next-line import/prefer-default-export
export const useSigningWorkflow = (
  domain: string,
  initialStep: SigningStep,
  initialStatus: SigningStatus,
  initialFileId: string | undefined,
  instance: string
): UseSigningWorkflow => {
  const [status, setStatus] = useState(initialStatus);
  const [step, setStep] = useState(initialStep);

  const [fileId, setFileId] = useState(initialFileId);
  const [uploadInfo, setUploadInfo] = useState<StorageLinkInfo>();
  const [partId, setPartId] = useState<string>();
  const [signedFile, setSignedFile] = useState<ArrayBuffer>();
  const [pollCancelToken, setPollCancelToken] = useState<CancelTokenSource>();
  const [isWaitingForPoll, setIsWaitingForPoll] = useState(false);

  const { config } = useContext(ConfigContext);

  const errorMessage = useRef<string>();
  const accountId = useRef<string>();
  const tcVersion = useRef<string>();
  const datFile = useRef<string>();
  const clientType = useRef<string>();

  const { calculateHash } = useHashCalculator();
  const { updateStatus } = useSigningRequestCookies();

  const abort = useCallback(() => {
    if (pollCancelToken) {
      pollCancelToken.cancel();
    }
  }, [pollCancelToken]);

  /**
   * This hook drives the entire workflow based on step changes.
   */
  useEffect(() => {
    /**
     * Any time we see a local step change, we will
     * also update the cookies so we can restore later if needed.
     */
    updateStatus(domain, status, step);

    if (status !== SigningStatus.InProgress) {
      return;
    }

    (async () => {
      try {
        /**
         * Request signature from SFI.
         */
        if (step === SigningStep.Signing) {
          if (!tcVersion.current) {
            throw new Error('Tanium Client version is required');
          }

          if (!datFile.current) {
            throw new Error('tanium-init.dat file is required');
          }

          if (!clientType.current) {
            throw new Error('Client type is required');
          }

          const newFileId = await Api.createSignedInstaller(
            config!.signingServiceHost,
            tcVersion.current,
            datFile.current,
            clientType.current
          );

          setFileId(newFileId);
          updateStatus(domain, status, step, fileId);
          setStep(SigningStep.Polling);
        }

        /**
         * Poll SFI and wait for completion.
         */
        if (step === SigningStep.Polling) {
          if (!fileId) {
            throw new Error('Cannot poll for undefined file ID');
          }

          if (pollCancelToken) {
            pollCancelToken.cancel();
          }

          const cancelToken = axios.CancelToken.source();
          setPollCancelToken(cancelToken);

          const file = await Api.pollSignatureStatus(
            config!.signingServiceHost,
            fileId,
            cancelToken.token,
            setIsWaitingForPoll
          );

          setSignedFile(file);
          setStep(SigningStep.FetchingUploadDestination);
        }

        /**
         * Determine the S3 upload destination.
         */
        if (step === SigningStep.FetchingUploadDestination) {
          if (!signedFile) {
            throw new Error('Cannot determine upload destination for undefined file');
          }

          if (!accountId.current) {
            throw new Error('Cannot determine upload destination without a account ID');
          }

          const sha256 = await calculateHash(signedFile);
          const size = signedFile.byteLength;
          const uploadDestination = await Api.getUploadDestination(
            accountId.current,
            sha256,
            size,
            instance,
            config!.mdmDomainName
          );

          setUploadInfo(uploadDestination);

          if (!uploadDestination) {
            setStatus(SigningStatus.Failure);
            return;
          }

          setStep(SigningStep.Uploading);
        }

        /**
         * Upload the signed file to S3.
         */
        if (step === SigningStep.Uploading) {
          if (!signedFile) {
            setStatus(SigningStatus.Failure);
            return;
          }

          const eTag = await Api.uploadFileToS3(signedFile, uploadInfo!.uploadURLs[0].partUploadURL);

          setPartId((eTag ?? '').replace(/"/g, ''));
          setStep(SigningStep.Finalizing);
        }

        /**
         * Call MDMC to finalize.
         */
        if (step === SigningStep.Finalizing) {
          const uploadId = uploadInfo?.uploadId;
          if (!uploadId) {
            throw new Error('Cannot finalize without an upload ID');
          }

          if (!partId) {
            throw new Error('Cannot finalize without a part ID');
          }

          if (!accountId.current) {
            throw new Error('Cannot finalize without a account ID');
          }

          const partInfos: PartInfo[] = [{ partId, partSequenceNumber: 1 }];
          const version = '1.0';

          await Api.finalizeInstallerUpload(
            accountId.current,
            uploadId,
            partInfos,
            version,
            instance,
            config!.mdmDomainName
          );

          setStatus(SigningStatus.Success);
          updateStatus(domain, SigningStatus.Success, step);
        }
      } catch (e) {
        errorMessage.current = (e as Error).message;
        setStatus(SigningStatus.Failure);
        updateStatus(domain, SigningStatus.Failure, step);
      }
    })();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [step]);

  return {
    status,
    step,
    tcVersion,
    datFile,
    clientType,
    accountId,
    errorMessage,
    isWaitingForPoll,
    setStatus,
    setStep,
    abort,
  };
};
