import { useState, useCallback, useContext } from 'react';
import { ThemeFontWeights } from '@tanium/theme-data';
import { Button } from '@tanium/react-button';
import { Well } from '@tanium/react-well';
import { Box } from '@tanium/react-box';
import { CaretLeftIcon } from '@tanium/react-graphics';
import { TextWithIcon } from '@tanium/react-text-with-icon';
import { NotificationBase } from '@tanium/react-notifications-base';
import { Panel } from '@tanium/react-panel';
import { BackBox, TitleBox } from './Styling';
import { Api } from '../utils/api';
import WelcomeComponent from '../components/TaniumComponents/WelcomeComponent';
import InfoComponent from '../components/TaniumComponents/InfoComponent';
import UploadComponent from '../components/TaniumComponents/UploadComponent';
import HandshakeComponent from '../components/TaniumComponents/HandshakeComponent';
import CustomerTableComponent from '../components/TaniumComponents/CustomerComponent';
import protectedRoute from './ProtectedRoute';
import { ConfigContext } from '../components/TaniumComponents/ConfigContext';
import { UploadBox } from '../components/Utils/Styling';
import HeaderComponent from '../components/Header/HeaderComponent';

const OnboardingPage = () => {
  const [renderTable, setRenderTable] = useState(false);
  const [hasStart, setStart] = useState(false);
  const [sentRequest, setSend] = useState(false);
  const [hasUploaded, setHasUploaded] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');
  const [, setDomain] = useState('');
  const [, setEmail] = useState('');
  const [userInstance, setUserInstance] = useState('');
  const [handshakeFile, setHandshakeFile] = useState<Blob | null>(null);

  const { config } = useContext(ConfigContext);

  const onFileSubmit = useCallback(
    (file: File) => {
      setHasUploaded(false);
      setErrorMessage('');
      Api.uploadOnboardingFile(file, userInstance, config!.mdmDomainName)
        .then((apiResponse: Blob) => {
          setHasUploaded(true);
          setUserInstance('');
          setHandshakeFile(apiResponse);
        })
        .catch((err) => {
          // eslint-disable-next-line no-console
          console.log(err);
          setErrorMessage(String(err));
        });
    },
    [config, userInstance]
  );

  // Triggers a download file
  const downloadHandshakeFile = () => {
    const blobUrl = URL.createObjectURL(handshakeFile!);

    const anchor = document.createElement('a');
    anchor.href = blobUrl;
    anchor.target = '_blank';
    anchor.download = 'handshake_configuration.pem';

    // Auto click on a element, trigger the file download
    anchor.click();

    // This is required
    URL.revokeObjectURL(blobUrl);
  };

  const setUpCustomer = () => {
    setDomain('');
    setEmail('');
    setUserInstance('');
    setSend(false);
    setHasUploaded(false);
    setRenderTable(false);
    setStart(true);
  };

  const loadTable = () => {
    setRenderTable(true);
    setStart(false);
  };

  return (
    <HeaderComponent>
      <Box>
        {hasStart ? (
          <Box>
            <div onClick={loadTable} style={{ cursor: 'pointer' }}>
              <BackBox m={1}>
                <TextWithIcon leadingIcon={CaretLeftIcon}>Back</TextWithIcon>
              </BackBox>
            </div>
            <TitleBox fontWeight={ThemeFontWeights.Bold}>Set Up Customer</TitleBox>
            <Box p={1}>
              <Well>
                <InfoComponent
                  hasStart={hasStart}
                  setStart={setStart}
                  setSend={setSend}
                  setHasUploaded={setHasUploaded}
                  setUserInstance={setUserInstance}
                  sentRequest={sentRequest}
                  instanceList={config!.instanceList ?? []}
                />
                {sentRequest ? (
                  <Box>
                    <UploadBox>
                      <Panel title={'Step 2: Upload Onboarding File'}>
                        <UploadComponent onFileSubmit={onFileSubmit} />
                      </Panel>
                    </UploadBox>
                    {errorMessage ? (
                      <NotificationBase type="error" role="status">
                        {errorMessage}
                      </NotificationBase>
                    ) : null}
                    {hasUploaded ? <HandshakeComponent downloadHandshakeFile={downloadHandshakeFile} /> : null}
                  </Box>
                ) : null}
              </Well>
            </Box>
          </Box>
        ) : (
          <Box>
            {renderTable ? (
              <Box>
                <TitleBox mt={8} pt={2} fontWeight={ThemeFontWeights.Bold}>
                  <Box>MDM Onboarder</Box>
                  <Box flexGrow={1} />
                  <Box marginRight={1}>
                    <Button onClick={setUpCustomer}>Set Up Customer</Button>
                  </Box>
                </TitleBox>
                <CustomerTableComponent
                  setEmail={setEmail}
                  setDomain={setDomain}
                  setRenderTable={setRenderTable}
                  setStart={setStart}
                  setSend={setSend}
                  setHasUploaded={setHasUploaded}
                />
              </Box>
            ) : (
              <Box>
                <TitleBox fontWeight={ThemeFontWeights.Bold} mt={8} pt={2}>
                  MDM Onboarder
                </TitleBox>
                <WelcomeComponent hasStart={hasStart} setStart={setStart} />
              </Box>
            )}
          </Box>
        )}
      </Box>
    </HeaderComponent>
  );
};

export default protectedRoute(OnboardingPage);
