import styled from '@tanium/react-emotion-9';
import { css } from '@tanium/style-system';
import { Box } from '@tanium/react-box';
import { defineColor } from '@tanium/react-theme-definition';
import { HEX_7F869D } from '@tanium/colors';

const InfoBox = styled(Box)(
  css({
    borderStyle: 'solid',
    borderColor: '#DCDFE6',
    borderWidth: '0.1em',
    borderRadius: '0.4em',
  })
);

const FormBox = styled(Box)(
  css({
    fontSize: '0.9em',
    width: '100%',
  })
);

const InfoTextStyling = {
  width: '100',
  height: '80%',
};

const UploadBox = styled(Box)(
  css({
    borderStyle: 'solid',
    borderColor: '#DCDFE6',
    borderWidth: '0.1em',
    borderRadius: '0.4em',
    marginTop: '1em',
  })
);

const UploadTextStyling = {
  width: '280px',
  marginLeft: '0.35em',
  height: '100%',
};

const TextBox = styled(Box)(
  css({
    fontSize: '0.8em',
    color: defineColor([HEX_7F869D, HEX_7F869D]),
  })
);

export { InfoBox, FormBox, InfoTextStyling, UploadBox, UploadTextStyling, TextBox };
