import React, { useState } from 'react';
import { Panel } from '@tanium/react-panel';
import { Form, FormRow, FormValueChangeEvent } from '@tanium/react-form';
import { FormSelectInput } from '@tanium/react-form-select-input';
import { Button } from '@tanium/react-button';
import { NotificationBase } from '@tanium/react-notifications-base';
import { InfoBox, FormBox } from '../Utils/Styling';

interface Props {
  setStart: (e: boolean) => void;
  hasStart: boolean;
  sentRequest: boolean;
  setSend: (e: boolean) => void;
  setHasUploaded: (e: boolean) => void;
  setUserInstance: (e: string) => void;
  instanceList: string[];
}

interface FormData {
  instance: {
    value: string;
    label: string;
  };
}

const InfoComponent: React.VFC<Props> = ({
  setStart,
  hasStart,
  setSend,
  setHasUploaded,
  setUserInstance,
  sentRequest,
  instanceList,
}) => {
  const onClickCancel = (e: React.MouseEvent) => {
    e.preventDefault();
    if (hasStart === true) {
      setStart(false);
    }
  };

  const [hasSent, setHasSent] = useState<boolean>(sentRequest);
  const [, setIsDisabled] = useState<boolean>(sentRequest);
  const defaultForm = {
    instance: { value: '', label: '' },
  };
  const [, setFormData] = useState<FormData>(defaultForm);

  const onClickSend = (e: React.MouseEvent) => {
    e.preventDefault();
    setIsDisabled(true);
    setSend(true);
    setHasSent(true);
  };

  const onClickEdit = (e: React.MouseEvent) => {
    e.preventDefault();
    setIsDisabled(false);
    setHasUploaded(false);
    setSend(false);
  };

  const onValueChange = (e: FormValueChangeEvent<FormData>) => {
    setFormData({
      instance: e.value.instance!,
    });
    setUserInstance(e.value.instance!.value);
  };

  let buttons: React.ReactFragment;
  if (hasSent) {
    buttons = (
      <>
        <Button disabled={true} type="button">
          Set Instance to Continue
        </Button>
        <Button onClick={onClickEdit} variant="secondary" type="button">
          Edit
        </Button>
        <Button onClick={onClickSend} variant="secondary" type="submit">
          Resend
        </Button>
      </>
    );
  } else {
    buttons = (
      <>
        <Button onClick={onClickSend} type="submit">
          Set Instance to Continue
        </Button>
        <Button variant="secondary" onClick={onClickCancel} type="button">
          Cancel
        </Button>
      </>
    );
  }

  // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access
  const instanceOptions = instanceList.map((item: string) => {
    return { value: item, label: item };
  });

  return (
    <InfoBox>
      <Panel title="Step 1: Customer Info">
        <FormBox>
          <Form buttons={buttons} onValueChange={onValueChange}>
            <FormRow
              labelId="instance"
              label="Customer's Instance"
              required={true}
              dataKey="instance"
              data-testid="instance-input"
            >
              <FormSelectInput
                isSearchable={false}
                options={instanceOptions}
                data-testid="instance-input"
                dataKey="instance"
              />
            </FormRow>
          </Form>
          {hasSent ? (
            <NotificationBase type="success" role="status">
              Please upload the customer provided file.
            </NotificationBase>
          ) : null}
        </FormBox>
      </Panel>
    </InfoBox>
  );
};

export default InfoComponent;
