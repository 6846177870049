import React from 'react';
import styled from '@tanium/react-emotion-9';
import { Button } from '@tanium/react-button';
import { Box } from '@tanium/react-box';
import { Tile } from '@tanium/react-tile';
import { Well } from '@tanium/react-well';
import { css } from '@tanium/style-system';
import { ThemeFonts, ThemeFontWeights } from '@tanium/theme-data';
import { HEX_A4A9B3 } from '@tanium/colors';
import { defineColor } from '@tanium/react-theme-definition';

interface Props {
  setStart: (e: boolean) => void;
  hasStart: boolean;
}

const WelcomeBox = styled(Box)(
  css({
    padding: '1em',
    textAlign: 'center',
  })
);

const TextBox = styled(Box)(
  css({
    fontSize: '0.8em',
    color: defineColor([HEX_A4A9B3, HEX_A4A9B3]),
  })
);

const WelcomeComponent: React.VFC<Props> = ({ setStart, hasStart }) => {
  const onClick = () => {
    if (hasStart === false) {
      setStart(true);
    }
  };

  return (
    <WelcomeBox>
      <Well>
        <Tile m={1} p={4}>
          <Box fontFamily={ThemeFonts.Main} fontWeight={ThemeFontWeights.Medium}>
            Welcome to MDM Onboarding
          </Box>
          <TextBox paddingBottom={2} paddingTop={1} fontFamily={ThemeFonts.Main} fontWeight={ThemeFontWeights.Medium}>
            This is where you can help setup an On-Prem Customer with an MDM instance.
          </TextBox>
          <Button onClick={onClick}>Get Started</Button>
        </Tile>
      </Well>
    </WelcomeBox>
  );
};

export default WelcomeComponent;
