import { useCallback } from 'react';

const COOKIE_KEY = 'signingstatus';

export enum SigningStatus {
  InProgress = 'in progress',
  Success = 'success',
  Failure = 'failure',
  NotStarted = 'not started',
  Unknown = 'unknown',
}

export enum SigningStep {
  /**
   * In this step, we pass the customer file to
   * the onboarding service and get back metadata
   * for the installer (e.g. tc version, tanium-init.dat)
   */
  FetchingMetaData,

  /**
   * In this step, a request is made to the
   * signing service.
   */
  Signing,

  /**
   * In this step, we poll and wait for completion
   * of the signing request. This step can take a while.
   */
  Polling,

  /**
   * In this step, a pre-signed S3 upload URL
   * is fetched from the cloud services.
   */
  FetchingUploadDestination,

  /**
   * In this step, the signed binary is
   * being uploaded to the pre-signed S3 URL.
   */
  Uploading,

  /**
   * In this step, cloud services are
   * made aware of the status and S3 location.
   */
  Finalizing,
}

export type SigningRequestDetails = {
  /**
   * The current status of the signing / upload process.
   */
  status: SigningStatus;

  /**
   * The current [[SigningStep]] we are in.
   */
  step: SigningStep;

  /**
   * The ID of the file returned by the signing service.
   */
  fileId: string | undefined;

  /**
   * The time at which the status was last updated.
   */
  updated: Date;
};

type StatusMapCookie = Record<string, SigningRequestDetails>;

type UseSigningRequestCookies = {
  getAllStatuses: () => StatusMapCookie;
  getStatus: (domain: string) => SigningRequestDetails;
  initializeStatus: (domain: string) => SigningRequestDetails;
  updateStatus: (
    domain: string,
    status: SigningStatus,
    step: SigningStep,
    fileId?: string
  ) => SigningRequestDetails | undefined;
};

export const useSigningRequestCookies = (): UseSigningRequestCookies => {
  /**
   * Fetches the entire status map from cookies.
   */
  const getAllStatuses = useCallback(() => {
    const cookie = document.cookie.split('; ').reduce((agg, currentCookie) => {
      const [key, value] = currentCookie.split('=');
      return key === COOKIE_KEY ? decodeURIComponent(value) : agg;
    }, '');

    try {
      return JSON.parse(cookie) as StatusMapCookie;
    } catch (e) {
      return {};
    }
  }, []);

  /**
   * Gets the status for a specific domain.
   */
  const getStatus = useCallback(
    (domain: string) => {
      const statusMap = getAllStatuses();
      return statusMap[domain];
    },
    [getAllStatuses]
  );

  /**
   * Updates the status for a specific domain.
   */
  const initializeStatus = useCallback(
    (domain: string) => {
      const statusMap = getAllStatuses();
      statusMap[domain] = {
        status: SigningStatus.NotStarted,
        step: SigningStep.Signing,
        updated: new Date(),
        fileId: undefined,
      };
      document.cookie = `${COOKIE_KEY}=${JSON.stringify(statusMap)}`;
      return getStatus(domain);
    },
    [getAllStatuses, getStatus]
  );

  /**
   * Updates the status for a specific domain.
   */
  const updateStatus = useCallback(
    (domain: string, status: SigningStatus, step: SigningStep, fileId?: string) => {
      if (!domain) return undefined;
      const statusMap = getAllStatuses();
      const currentStatus = getStatus(domain) ?? {};
      statusMap[domain] = {
        ...currentStatus,
        status,
        step,
        fileId: fileId ?? currentStatus.fileId,
        updated: new Date(),
      };
      document.cookie = `${COOKIE_KEY}=${JSON.stringify(statusMap)}`;
      return getStatus(domain);
    },
    [getStatus, getAllStatuses]
  );

  return {
    getAllStatuses,
    getStatus,
    initializeStatus,
    updateStatus,
  };
};
